import $ from 'jquery';
import 'slick-carousel/slick/slick'

let blogSlider = $('.blog-slider-block__slider');
if (blogSlider.length > 0) {
  let titles = blogSlider.find('.blog-slider-block-slide').last().attr('data-titles').split(',');

  blogSlider.slick({
    fade: true,
    cssEase: "ease-in-out",
    touchThreshold: 100,
    dots: true,
    arrows: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 5000,
    infinite: true,
    loop: true,
    customPaging: function(slick,index) {
      return '<span tabindex="0" class="body-medium">' + titles[index] + '</span>';
    },
  });
}


